require('./bootstrap.js');
require('./theme.js')
require('./validate.js');
require('moment');
require('./datetimepicker.js');
require('datatables.net-bs4');
require('sweetalert');
require('./global.js');
require('select2');
require('./cropper');
require('./sidebar');
require('./quill');
require('./icon-picker');
require('./formbuilder');