"use strict"
var activeLink = window.location.pathname;
var splitLinks = activeLink.split('/');
for (let i = splitLinks.length; i >= 0; i--) {
    var updatedLink = '';
    for (let j = 0; j <= i; j++) {
        if (splitLinks[j] != undefined) {
            updatedLink += splitLinks[j] + '/';
        }
    }
    var updatedURL = location.protocol + '//' + location.host + updatedLink.replace(/\/$/, "");
    if ($("#sidebar").find("a[href='" + updatedURL + "']").length != 0) {
        $("#sidebar").find("a[href='" + updatedURL + "']").parent('li').addClass('active')
        break;
    }
}